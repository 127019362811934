<script setup lang="ts">

</script>

<template>
  <div class="min-h-100vh flex flex-col">
    <LayoutHeader class="h-[--header-height]" />

    <main class="mt-[--header-height] flex-1 bg-[var(--main-color)]">
      <slot />
    </main>
  </div>
</template>

<style lang="scss" scoped>
</style>
