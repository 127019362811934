import { defineNuxtPlugin } from '#app/nuxt'
import { LazyArrowDown, LazyChevronLeft, LazyChevronLeftPipe, LazyClose, LazyDownloadStepArrows, LazyEarth, LazyEasyfunLogo, LazyErr404, LazyFootTopBarBg, LazyGameAdvantage1, LazyGameAdvantage2, LazyGameAdvantage3, LazyGameAdvantage4, LazyGameAdvantage5, LazyGameAdvantage6, LazyGameRecommendThumb, LazyGoTop, LazyLightning, LazyLogoName, LazyMediaShare, LazyNavMob, LazySearch, LazySearchHash, LazySearchNone, LazySearchNoneDark, LazyShareFaceBook, LazyShareOther, LazyShareReddit, LazyShareX, LazyIcon, LazyRenderCacheable } from '#components'
const lazyGlobalComponents = [
  ["ArrowDown", LazyArrowDown],
["ChevronLeft", LazyChevronLeft],
["ChevronLeftPipe", LazyChevronLeftPipe],
["Close", LazyClose],
["DownloadStepArrows", LazyDownloadStepArrows],
["Earth", LazyEarth],
["EasyfunLogo", LazyEasyfunLogo],
["Err404", LazyErr404],
["FootTopBarBg", LazyFootTopBarBg],
["GameAdvantage1", LazyGameAdvantage1],
["GameAdvantage2", LazyGameAdvantage2],
["GameAdvantage3", LazyGameAdvantage3],
["GameAdvantage4", LazyGameAdvantage4],
["GameAdvantage5", LazyGameAdvantage5],
["GameAdvantage6", LazyGameAdvantage6],
["GameRecommendThumb", LazyGameRecommendThumb],
["GoTop", LazyGoTop],
["Lightning", LazyLightning],
["LogoName", LazyLogoName],
["MediaShare", LazyMediaShare],
["NavMob", LazyNavMob],
["Search", LazySearch],
["SearchHash", LazySearchHash],
["SearchNone", LazySearchNone],
["SearchNoneDark", LazySearchNoneDark],
["ShareFaceBook", LazyShareFaceBook],
["ShareOther", LazyShareOther],
["ShareReddit", LazyShareReddit],
["ShareX", LazyShareX],
["Icon", LazyIcon],
["RenderCacheable", LazyRenderCacheable],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
