<script setup lang="ts">
interface Props {
  /** 404 找不到页面 422 请稍后再试 */
  statusCode: '404' | '422' | string | number
}

defineProps<Props>()

const { toIndex } = useRouterToPage()

const route = useRoute()
clientLog.log(`%cerror in route => ${route.fullPath}`, 'background:#C0392B;color:#fff;padding:2px 6px;border-radius: 4px;')
// onMounted(() => {
//   clearError({ redirect: toIndex({ hasReturnUrl: true }) })
// })

const { resetLanguage, languageLoading } = useLanguageStore()
resetLanguage()
languageLoading.resolve()
</script>

<template>
  <div class="text-center text-18px c-#fff9 lh-24px">
    <div class="mt-80px min-h-440px">
      <template v-if="String(statusCode) === '404'">
        <Icon class="aspect-ratio-32/17 h-auto max-w-320px w-53.3vw" name="Err404" />
      </template>

      <div class="c-#161636 font-900" lt-md="mt-16px mx-20px lh-28px" md="mt-40px text-28px lh-40px">
        {{ $t('error.404_title') }}
      </div>
      <div class="mx-auto mt-12px max-w-580px">
        <p class="text-center c-#161636 c-op-50" lt-md="mx-20px text-14px lh-20px" md="text-16px lh-24px">
          {{ $t('error.404_desc') }}
        </p>
      </div>
      <button
        class="ld-button mt-32px h-40px min-w-220px rd-8px px-24px text-16px ld-button-purple"
        @click="clearError({ redirect: toIndex({ hasReturnUrl: true }) })"
      >
        {{ $t('error.back_home') }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
