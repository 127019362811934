import revive_payload_client_QBRAyMiD5p from "/root/workspace/ld_x_gw_Wdkc/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_zw4buxwkhbnprkvp2lpr4gtbty/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_y5eVcEllJ4 from "/root/workspace/ld_x_gw_Wdkc/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_zw4buxwkhbnprkvp2lpr4gtbty/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_765alntwZz from "/root/workspace/ld_x_gw_Wdkc/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_zw4buxwkhbnprkvp2lpr4gtbty/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_IXl9eTHzUz from "/root/workspace/ld_x_gw_Wdkc/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_zw4buxwkhbnprkvp2lpr4gtbty/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_MSfBHtzXHb from "/root/workspace/ld_x_gw_Wdkc/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_zw4buxwkhbnprkvp2lpr4gtbty/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ELQGlA1Uu7 from "/root/workspace/ld_x_gw_Wdkc/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_zw4buxwkhbnprkvp2lpr4gtbty/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_dd17flVudV from "/root/workspace/ld_x_gw_Wdkc/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_zw4buxwkhbnprkvp2lpr4gtbty/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_6fuhnImRWW from "/root/workspace/ld_x_gw_Wdkc/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.3_typescript@5.6.2_vue@3.5.6_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/root/workspace/ld_x_gw_Wdkc/.nuxt/components.plugin.mjs";
import prefetch_client_vjMjVSDJYW from "/root/workspace/ld_x_gw_Wdkc/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_zw4buxwkhbnprkvp2lpr4gtbty/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WAi0LOihP4 from "/root/workspace/ld_x_gw_Wdkc/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.21.3_webpack-sources@3.2.3/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import sentry_client_xl9EKypJOR from "/root/workspace/ld_x_gw_Wdkc/node_modules/.pnpm/@sentry+nuxt@8.33.1_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._ojs7scwzb3llktgiqncfhbkmuy/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/root/workspace/ld_x_gw_Wdkc/.nuxt/sentry-client-config.mjs";
import unocss_MzCDxu9LMj from "/root/workspace/ld_x_gw_Wdkc/.nuxt/unocss.mjs";
import switch_locale_path_ssr_8tHeGvbJ14 from "/root/workspace/ld_x_gw_Wdkc/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.21.3_vue@3.5.6_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_hXbU2HGAqR from "/root/workspace/ld_x_gw_Wdkc/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.21.3_vue@3.5.6_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_q5Dt1kMhQD from "/root/workspace/ld_x_gw_Wdkc/node_modules/.pnpm/@nuxtjs+device@3.2.3_@parcel+watcher@2.4.1_@types+node@22.5.5_h3@1.12.0_ioredis@5.4.1_jiti@1._z5w2tvup2gmggfzn6fjf55m6oi/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_z1T7Ut20Gz from "/root/workspace/ld_x_gw_Wdkc/node_modules/.pnpm/@nuxt+icon@1.5.5_magicast@0.3.5_rollup@4.21.3_vite@5.4.6_@types+node@22.5.5_sass@1.79.1_terse_qdptla2umshepukqedot3aj6jm/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_dDVJ7MYCal from "/root/workspace/ld_x_gw_Wdkc/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.3_typesc_iwcv2j6hqs75serwr4docj5wm4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import devOrTest_client_iNa5av6maE from "/root/workspace/ld_x_gw_Wdkc/plugins/devOrTest.client.ts";
import directive_track_x6uiYrQHtT from "/root/workspace/ld_x_gw_Wdkc/plugins/directive-track.ts";
import lazy_img_A0S039QyXL from "/root/workspace/ld_x_gw_Wdkc/plugins/lazy-img.ts";
export default [
  revive_payload_client_QBRAyMiD5p,
  unhead_y5eVcEllJ4,
  router_765alntwZz,
  payload_client_IXl9eTHzUz,
  navigation_repaint_client_MSfBHtzXHb,
  check_outdated_build_client_ELQGlA1Uu7,
  chunk_reload_client_dd17flVudV,
  plugin_vue3_6fuhnImRWW,
  components_plugin_KR1HBZs4kY,
  prefetch_client_vjMjVSDJYW,
  plugin_WAi0LOihP4,
  sentry_client_xl9EKypJOR,
  sentry_client_config_o34nk2sJbg,
  unocss_MzCDxu9LMj,
  switch_locale_path_ssr_8tHeGvbJ14,
  i18n_hXbU2HGAqR,
  plugin_q5Dt1kMhQD,
  plugin_z1T7Ut20Gz,
  plugin_dDVJ7MYCal,
  devOrTest_client_iNa5av6maE,
  directive_track_x6uiYrQHtT,
  lazy_img_A0S039QyXL
]