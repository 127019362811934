<script setup lang="ts">
const { localeProperties } = useI18n()
const { initQueryData } = useQueryStore()

const { normalHead } = usePageHead()
normalHead()

if (import.meta.client) {
  initQueryData()
  const { initSdk } = useUserInfo()
  initSdk()
}
</script>

<template>
  <Head>
    <Link rel="icon" :href="getPublicImg('favicon.ico')" />
    <Link rel="canonical" :href="`${localeProperties.domain}${$route.path}`" />
  </Head>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
