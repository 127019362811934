import type { Directive } from 'vue'
import type { SupportLocale } from '~/config/i18n'
import type { LogEventKey } from '~/types/log'

interface TrackEventParams {
  value?: Record<string, any>
  /** 上报地区 默认当前语言 */
  locale?: SupportLocale
}

interface TrackParams {
  /** 上报key LogEventKey */
  id: LogEventKey
  /** 曝光参数 */
  exposure?: TrackEventParams
  /** 点击参数 */
  click?: TrackEventParams
  /** 浏览参数 */
  view?: TrackEventParams
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive<HTMLElement, TrackParams>('track', {
    mounted(el, binding) {
      const trackId = binding.value.id

      const { elementExposure, exposureReport, clickReport, viewReport } = useLogReport()

      // 曝光上报
      if (binding.value.exposure) {
        elementExposure(el, () => {
          exposureReport(trackId, {
            value: JSON.stringify(binding.value.exposure?.value || {}),
            locale: binding.value.exposure?.locale,
          })
        })
      }

      // 点击上报
      if (binding.value.click) {
        el.onclick = () => {
          clickReport(trackId, {
            value: JSON.stringify(binding.value.click?.value || {}),
            locale: binding.value.click?.locale,
          })
        }
      }

      if (binding.value.view) {
        viewReport(trackId, {
          value: JSON.stringify(binding.value.view?.value || {}),
          locale: binding.value.view?.locale,
        })
      }
    },

  })
})

declare module 'vue' {
  interface ComponentCustomProperties {
    vTrack: Directive<HTMLElement, TrackParams>
  }
}
