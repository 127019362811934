import type { SupportLocale } from '~/config/i18n'
import type { AppDetailPage, AppSimilar } from '~/types'

export function usePageHead() {
  const { public: { cdn } } = useRuntimeConfig()
  const { t, localeProperties, locales } = useI18n()

  const route = useRoute()

  /** 全局默认 */
  function normalHead() {
    const code = localeProperties.value.code as SupportLocale

    const fontHrefMap: Partial<Record<SupportLocale, string>> = {
      ar: 'fonts/Noto_Sans_Arabic/index.css',
      jp: 'fonts/Noto_Sans_JP/index.css',
      kr: 'fonts/Noto_Sans_KR/index.css',
      tw: 'fonts/Noto_Sans_TC/index.css',
      th: 'fonts/Noto_Sans_Thai/index.css',
      vn: 'fonts/Roboto/index.css',
    }

    const GTMIDs: Record<SupportLocale, string> = {
      en: 'GTM-NJJGFL2G',
      tw: 'GTM-MBWRDLF2',
      kr: 'GTM-ND8BH9HZ',
      vn: 'GTM-PTNTGPFL',
      th: 'GTM-WKRR2XV9',
      pt: 'GTM-M8HGGPTW',
      es: 'GTM-PSBVK46H',
      fr: 'GTM-54K9ZB65',
      de: 'GTM-5DJHFMSR',
      jp: 'GTM-WB8D4F4Z',
      id: 'GTM-TN7HJ4TZ',
      ar: 'GTM-NJR9QG2M',
      ru: 'GTM-TG3G8CJC',
    }
    useSeoMeta({
      title: 'LDPlayer X',
      charset: 'utf-8',
      viewport: 'width=device-width, initial-scale=1, maximum-scale=1 user-scalable=0',

      ogTitle: 'LDPlayer X',
      ogDescription: 'LDPlayer X',
      ogImage: getPublicImg('og-image.webp'),

      twitterTitle: 'LDPlayer X',
      twitterDescription: 'LDPlayer X',
      twitterCard: 'summary',
      twitterImage: getPublicImg('og-image.webp'),
    })

    useHead({
      script: [
        ...flatTrim(
          isProd() && [
            // 全部域名的gtm
            { tagPosition: 'bodyClose', innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-MHSHHR33');` },
            // 各个域名的gtm
            { tagPosition: 'bodyClose', innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${GTMIDs[code]}');` },
            // 日志上报
            { tagPosition: 'bodyClose', innerHTML: `!(function(c,b,d,a){c[a]||(c[a]={});c[a].config={pid:"edouv2osjk@6ce4f3d85d79938",appType:"web",tag:"${localeProperties.value.code}",imgUrl:"https://retcode-us-west-1.arms.aliyuncs.com/r.png?",sendResource:true,enableLinkTrace:true, behavior:true,useFmp:true,enableConsole:true}; with(b)with(body)with(insertBefore(createElement("script"),firstChild))setAttribute("crossorigin","",src=d)})(window,document,"https://sdk.rum.aliyuncs.com/v1/bl.js","__bl");` },
          ],
        ) as any,
      ],
      noscript: [
        ...flatTrim(
          isProd() && [
            { innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTMIDs[code]}" height="0" width="0" style="display:none;visibility:hidden"></iframe>` },
          ],
        ),
      ],
    })

    useHeadSafe({
      htmlAttrs: {
        lang: localeProperties.value.language,
        dir: localeProperties.value.code === 'ar' ? 'rtl' : 'ltr',
      },
      link: [
        { rel: 'preconnect', href: cdn },
        { rel: 'dns-prefetch', href: cdn },
        { rel: 'stylesheet', href: `${cdn}fonts/Noto_Sans/index.css` },
        ...flatTrim(
          !!fontHrefMap[code] && [{ rel: 'stylesheet', href: `${cdn}${fontHrefMap[code]}` }],
        ),
      ],

      meta: [
        ...flatTrim(
          !isProd() && [
            { name: 'robots', content: 'noindex, nofollow' },
            { name: 'googlebot', content: 'noindex' },
          ],
        ),
      ],
    })
  }

  /** 首页 */
  function indexHead() {
    useSeoMeta({
      title: t('seo.home_tkd_title'),
      keywords: t('seo.home_tkd_keyword'),
      description: t('seo.home_tkd_desc'),
    })

    useHeadSafe({
      link: generateAlternate(),
    })
  }

  /** 搜索 */
  function searchHead() {
    useSeoMeta({
      title: t('seo.search_tkd_title'),
      description: t('seo.search_tkd_desc'),
    })
  }

  /** 关于 */
  function aboutHead() {
    useSeoMeta({
      title: t('seo.about_tkd_title'),
      description: t('seo.about_tkd_desc'),
    })
  }

  /** 游戏集合页 */
  function appsHead() {
    useSeoMeta({
      title: t('seo.game_center_tkd_title'),
      description: t('seo.game_center_tkd_desc'),
    })

    useHeadSafe({
      link: unref(locales).map((i) => {
        const href = route.path
        // 没有路径的就不生成
        return { rel: 'alternate', hrefLang: () => i.alternate, href: () => `${i.domain}${href}` }
      }),
    })
  }

  /**
   * 分类集合页
   * @param type 分类类型
   */
  function appTypeHead(type: string | undefined) {
    useSeoMeta({
      title: t('seo.game_type_tkd_title', { type }),
      description: t('seo.game_type_tkd_desc', { type }),
    })
  }

  /**
   * 游戏详情页
   * @param game 游戏信息
   */
  function appDetailHead(game: MaybeRef<AppDetailPage | null>) {
    const gameName = computed(() => unref(game)?.gameDetails?.gameName)
    const description = computed(() => unref(game)?.gameDetails?.description || '')
    const firstGameImge = computed(() => unref(game)?.gameDetails.headBanner[0] || '')
    const gameAlternate = computed(() => {
      const otherSeo = unref(game)?.otherLanguageGameSeo || {}
      return generateAlternate(Object.assign({ [localeProperties.value.code]: route.path }, otherSeo))
    })

    useSeoMeta({
      title: t('seo.game_detail_tkd_title', { game: gameName.value }),
      keywords: t('seo.game_detail_tkd_keyword', { game: gameName.value }),
      description: t('seo.game_detail_tkd_desc', { game: gameName.value }) + description.value,

      ogTitle: t('seo.game_detail_tkd_title', { game: gameName.value }),
      ogDescription: t('seo.game_detail_tkd_desc', { game: gameName.value }) + description.value,
      ogUrl: `${localeProperties.value.domain}${route.path}`,
      ogImage: firstGameImge,

      twitterTitle: t('seo.game_detail_tkd_title', { game: gameName.value }),
      twitterDescription: t('seo.game_detail_tkd_desc', { game: gameName.value }) + description.value,
      twitterImage: firstGameImge,
    })

    useHeadSafe({
      link: gameAlternate.value,
    })
  }

  /**
   * 开发商游戏页
   * @param developer 开发商信息
   * @param games 游戏内容
   */
  function developerHead(developer: MaybeRef<string>, games: MaybeRef<AppSimilar[] | null>) {
    const gamesName = computed(() => (unref(games) || []).slice(0, 3).map(i => i.gameName).join(','))

    useSeoMeta({
      title: t('seo.developer_tkd_title', { developer: unref(developer) }),
      description: t('seo.developer_tkd_desc', { developer: unref(developer), games: unref(gamesName) }),
    })
  }

  /**
   * 落地页
   * @param title
   */
  function ldhHead(title: string | undefined) {
    useSeoMeta({
      title,
    })
  }
  return {
    normalHead,
    indexHead,
    searchHead,
    aboutHead,
    appsHead,
    appTypeHead,
    appDetailHead,
    developerHead,
    ldhHead,
  }
}

/** 扁平化第一层 并 过滤掉里面包含undefined、false、0、empty 的内容 */
function flatTrim<T extends any[]>(...arr: T) {
  return arr.flat<T>(1).filter(Boolean) as Exclude<T[0], false>
}

/** 根据传进来的url去生成指定的alternate */
function generateAlternate(subPathMap?: Partial<Record<SupportLocale, string>>) {
  const { $i18n: { locales } } = useNuxtApp()
  const route = useRoute()

  return locales.value.map((i) => {
    const href = subPathMap ? subPathMap[i.code as SupportLocale] : route.path
    // 没有路径的就不生成
    return !href ? undefined : { rel: 'alternate', hrefLang: i.alternate, href: `${i.domain}${href}` }
  }).filter(Boolean) as Record<'rel' | 'hrefLang' | 'href', string>[]
}
