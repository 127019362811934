<script setup lang="ts">
const props = defineProps<{
  error: Record<string, any>
}>()
// 客户端报错大多数是各种操作(click)执行错误
if (import.meta.client) {
  clientLog.group('error in client')
  clientLog.log('time =>', new Date().toLocaleString())
  Object.entries(props.error).forEach(([k, v]) => v && clientLog.log(`${k} =>`, v))
  clientLog.groupEnd()
}
else {
  serverLog.error()
  serverLog.error('error in server')
  serverLog.error('time =>', new Date().toLocaleString())
  Object.entries(props.error).forEach(([k, v]) => v && serverLog.error(`${k} =>`, v))
  serverLog.error()
}

const { normalHead } = usePageHead()
normalHead()
</script>

<template>
  <NuxtLayout name="default">
    <ErrorPage :status-code="error.statusCode" />
  </NuxtLayout>
</template>

<style lang="scss" scoped></style>
