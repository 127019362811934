import { default as _91_46_46_46error_93I2tM4FR9JZMeta } from "/root/workspace/ld_x_gw_Wdkc/pages/[...error].vue?macro=true";
import { default as _91type_93_46htmlId07qAX5kmMeta } from "/root/workspace/ld_x_gw_Wdkc/pages/apps/[type].html.vue?macro=true";
import { default as _91seo_93_46html9o7cxQWIgVMeta } from "/root/workspace/ld_x_gw_Wdkc/pages/apps/[type]/[seo].html.vue?macro=true";
import { default as _91seo_93_46htmld9a208MiDHMeta } from "/root/workspace/ld_x_gw_Wdkc/pages/blogs/[seo].html.vue?macro=true";
import { default as _91name_93_46html0WSQvVrQsmMeta } from "/root/workspace/ld_x_gw_Wdkc/pages/developers/[name].html.vue?macro=true";
import { default as index_45oldaRZOhLTnmIMeta } from "/root/workspace/ld_x_gw_Wdkc/pages/index-old.vue?macro=true";
import { default as indexRAuCLOD85RMeta } from "/root/workspace/ld_x_gw_Wdkc/pages/index.vue?macro=true";
import { default as _91seo_93_46htmlZr7ZNWHCosMeta } from "/root/workspace/ld_x_gw_Wdkc/pages/ldh/[seo].html.vue?macro=true";
import { default as about_46htmlnLFL2ojP0EMeta } from "/root/workspace/ld_x_gw_Wdkc/pages/other/about.html.vue?macro=true";
import { default as gdpr_46html66Poq7F74HMeta } from "/root/workspace/ld_x_gw_Wdkc/pages/other/gdpr.html.vue?macro=true";
import { default as privacy_46htmlpUdxQS4q6SMeta } from "/root/workspace/ld_x_gw_Wdkc/pages/other/privacy.html.vue?macro=true";
import { default as protocol_46html3jWw1aFr4XMeta } from "/root/workspace/ld_x_gw_Wdkc/pages/other/protocol.html.vue?macro=true";
import { default as searchp56ZVFH42HMeta } from "/root/workspace/ld_x_gw_Wdkc/pages/search.vue?macro=true";
import { default as testPzJJRUQQeZMeta } from "/root/workspace/ld_x_gw_Wdkc/pages/test.vue?macro=true";
export default [
  {
    name: "error___tw",
    path: "/:error(.*)*",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/[...error].vue")
  },
  {
    name: "error___kr",
    path: "/:error(.*)*",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/[...error].vue")
  },
  {
    name: "error___vn",
    path: "/:error(.*)*",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/[...error].vue")
  },
  {
    name: "error___th",
    path: "/:error(.*)*",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/[...error].vue")
  },
  {
    name: "error___ru",
    path: "/:error(.*)*",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/[...error].vue")
  },
  {
    name: "error___pt",
    path: "/:error(.*)*",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/[...error].vue")
  },
  {
    name: "error___es",
    path: "/:error(.*)*",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/[...error].vue")
  },
  {
    name: "error___fr",
    path: "/:error(.*)*",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/[...error].vue")
  },
  {
    name: "error___de",
    path: "/:error(.*)*",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/[...error].vue")
  },
  {
    name: "error___jp",
    path: "/:error(.*)*",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/[...error].vue")
  },
  {
    name: "error___id",
    path: "/:error(.*)*",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/[...error].vue")
  },
  {
    name: "error___ar",
    path: "/:error(.*)*",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/[...error].vue")
  },
  {
    name: "error___en",
    path: "/:error(.*)*",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/[...error].vue")
  },
  {
    name: "apps-type.html___tw",
    path: "/apps/:type().html",
    meta: _91type_93_46htmlId07qAX5kmMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/apps/[type].html.vue")
  },
  {
    name: "apps-type.html___kr",
    path: "/apps/:type().html",
    meta: _91type_93_46htmlId07qAX5kmMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/apps/[type].html.vue")
  },
  {
    name: "apps-type.html___vn",
    path: "/apps/:type().html",
    meta: _91type_93_46htmlId07qAX5kmMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/apps/[type].html.vue")
  },
  {
    name: "apps-type.html___th",
    path: "/apps/:type().html",
    meta: _91type_93_46htmlId07qAX5kmMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/apps/[type].html.vue")
  },
  {
    name: "apps-type.html___ru",
    path: "/apps/:type().html",
    meta: _91type_93_46htmlId07qAX5kmMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/apps/[type].html.vue")
  },
  {
    name: "apps-type.html___pt",
    path: "/apps/:type().html",
    meta: _91type_93_46htmlId07qAX5kmMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/apps/[type].html.vue")
  },
  {
    name: "apps-type.html___es",
    path: "/apps/:type().html",
    meta: _91type_93_46htmlId07qAX5kmMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/apps/[type].html.vue")
  },
  {
    name: "apps-type.html___fr",
    path: "/apps/:type().html",
    meta: _91type_93_46htmlId07qAX5kmMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/apps/[type].html.vue")
  },
  {
    name: "apps-type.html___de",
    path: "/apps/:type().html",
    meta: _91type_93_46htmlId07qAX5kmMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/apps/[type].html.vue")
  },
  {
    name: "apps-type.html___jp",
    path: "/apps/:type().html",
    meta: _91type_93_46htmlId07qAX5kmMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/apps/[type].html.vue")
  },
  {
    name: "apps-type.html___id",
    path: "/apps/:type().html",
    meta: _91type_93_46htmlId07qAX5kmMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/apps/[type].html.vue")
  },
  {
    name: "apps-type.html___ar",
    path: "/apps/:type().html",
    meta: _91type_93_46htmlId07qAX5kmMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/apps/[type].html.vue")
  },
  {
    name: "apps-type.html___en",
    path: "/apps/:type().html",
    meta: _91type_93_46htmlId07qAX5kmMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/apps/[type].html.vue")
  },
  {
    name: "apps-type-seo.html___tw",
    path: "/apps/:type()/:seo().html",
    meta: _91seo_93_46html9o7cxQWIgVMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/apps/[type]/[seo].html.vue")
  },
  {
    name: "apps-type-seo.html___kr",
    path: "/apps/:type()/:seo().html",
    meta: _91seo_93_46html9o7cxQWIgVMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/apps/[type]/[seo].html.vue")
  },
  {
    name: "apps-type-seo.html___vn",
    path: "/apps/:type()/:seo().html",
    meta: _91seo_93_46html9o7cxQWIgVMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/apps/[type]/[seo].html.vue")
  },
  {
    name: "apps-type-seo.html___th",
    path: "/apps/:type()/:seo().html",
    meta: _91seo_93_46html9o7cxQWIgVMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/apps/[type]/[seo].html.vue")
  },
  {
    name: "apps-type-seo.html___ru",
    path: "/apps/:type()/:seo().html",
    meta: _91seo_93_46html9o7cxQWIgVMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/apps/[type]/[seo].html.vue")
  },
  {
    name: "apps-type-seo.html___pt",
    path: "/apps/:type()/:seo().html",
    meta: _91seo_93_46html9o7cxQWIgVMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/apps/[type]/[seo].html.vue")
  },
  {
    name: "apps-type-seo.html___es",
    path: "/apps/:type()/:seo().html",
    meta: _91seo_93_46html9o7cxQWIgVMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/apps/[type]/[seo].html.vue")
  },
  {
    name: "apps-type-seo.html___fr",
    path: "/apps/:type()/:seo().html",
    meta: _91seo_93_46html9o7cxQWIgVMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/apps/[type]/[seo].html.vue")
  },
  {
    name: "apps-type-seo.html___de",
    path: "/apps/:type()/:seo().html",
    meta: _91seo_93_46html9o7cxQWIgVMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/apps/[type]/[seo].html.vue")
  },
  {
    name: "apps-type-seo.html___jp",
    path: "/apps/:type()/:seo().html",
    meta: _91seo_93_46html9o7cxQWIgVMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/apps/[type]/[seo].html.vue")
  },
  {
    name: "apps-type-seo.html___id",
    path: "/apps/:type()/:seo().html",
    meta: _91seo_93_46html9o7cxQWIgVMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/apps/[type]/[seo].html.vue")
  },
  {
    name: "apps-type-seo.html___ar",
    path: "/apps/:type()/:seo().html",
    meta: _91seo_93_46html9o7cxQWIgVMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/apps/[type]/[seo].html.vue")
  },
  {
    name: "apps-type-seo.html___en",
    path: "/apps/:type()/:seo().html",
    meta: _91seo_93_46html9o7cxQWIgVMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/apps/[type]/[seo].html.vue")
  },
  {
    name: "blogs-seo.html___tw",
    path: "/blogs/:seo().html",
    meta: _91seo_93_46htmld9a208MiDHMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/blogs/[seo].html.vue")
  },
  {
    name: "blogs-seo.html___kr",
    path: "/blogs/:seo().html",
    meta: _91seo_93_46htmld9a208MiDHMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/blogs/[seo].html.vue")
  },
  {
    name: "blogs-seo.html___vn",
    path: "/blogs/:seo().html",
    meta: _91seo_93_46htmld9a208MiDHMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/blogs/[seo].html.vue")
  },
  {
    name: "blogs-seo.html___th",
    path: "/blogs/:seo().html",
    meta: _91seo_93_46htmld9a208MiDHMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/blogs/[seo].html.vue")
  },
  {
    name: "blogs-seo.html___ru",
    path: "/blogs/:seo().html",
    meta: _91seo_93_46htmld9a208MiDHMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/blogs/[seo].html.vue")
  },
  {
    name: "blogs-seo.html___pt",
    path: "/blogs/:seo().html",
    meta: _91seo_93_46htmld9a208MiDHMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/blogs/[seo].html.vue")
  },
  {
    name: "blogs-seo.html___es",
    path: "/blogs/:seo().html",
    meta: _91seo_93_46htmld9a208MiDHMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/blogs/[seo].html.vue")
  },
  {
    name: "blogs-seo.html___fr",
    path: "/blogs/:seo().html",
    meta: _91seo_93_46htmld9a208MiDHMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/blogs/[seo].html.vue")
  },
  {
    name: "blogs-seo.html___de",
    path: "/blogs/:seo().html",
    meta: _91seo_93_46htmld9a208MiDHMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/blogs/[seo].html.vue")
  },
  {
    name: "blogs-seo.html___jp",
    path: "/blogs/:seo().html",
    meta: _91seo_93_46htmld9a208MiDHMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/blogs/[seo].html.vue")
  },
  {
    name: "blogs-seo.html___id",
    path: "/blogs/:seo().html",
    meta: _91seo_93_46htmld9a208MiDHMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/blogs/[seo].html.vue")
  },
  {
    name: "blogs-seo.html___ar",
    path: "/blogs/:seo().html",
    meta: _91seo_93_46htmld9a208MiDHMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/blogs/[seo].html.vue")
  },
  {
    name: "blogs-seo.html___en",
    path: "/blogs/:seo().html",
    meta: _91seo_93_46htmld9a208MiDHMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/blogs/[seo].html.vue")
  },
  {
    name: "developers-name.html___tw",
    path: "/developers/:name().html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/developers/[name].html.vue")
  },
  {
    name: "developers-name.html___kr",
    path: "/developers/:name().html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/developers/[name].html.vue")
  },
  {
    name: "developers-name.html___vn",
    path: "/developers/:name().html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/developers/[name].html.vue")
  },
  {
    name: "developers-name.html___th",
    path: "/developers/:name().html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/developers/[name].html.vue")
  },
  {
    name: "developers-name.html___ru",
    path: "/developers/:name().html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/developers/[name].html.vue")
  },
  {
    name: "developers-name.html___pt",
    path: "/developers/:name().html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/developers/[name].html.vue")
  },
  {
    name: "developers-name.html___es",
    path: "/developers/:name().html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/developers/[name].html.vue")
  },
  {
    name: "developers-name.html___fr",
    path: "/developers/:name().html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/developers/[name].html.vue")
  },
  {
    name: "developers-name.html___de",
    path: "/developers/:name().html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/developers/[name].html.vue")
  },
  {
    name: "developers-name.html___jp",
    path: "/developers/:name().html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/developers/[name].html.vue")
  },
  {
    name: "developers-name.html___id",
    path: "/developers/:name().html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/developers/[name].html.vue")
  },
  {
    name: "developers-name.html___ar",
    path: "/developers/:name().html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/developers/[name].html.vue")
  },
  {
    name: "developers-name.html___en",
    path: "/developers/:name().html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/developers/[name].html.vue")
  },
  {
    name: "index-old___tw",
    path: "/index-old",
    meta: index_45oldaRZOhLTnmIMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/index-old.vue")
  },
  {
    name: "index-old___kr",
    path: "/index-old",
    meta: index_45oldaRZOhLTnmIMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/index-old.vue")
  },
  {
    name: "index-old___vn",
    path: "/index-old",
    meta: index_45oldaRZOhLTnmIMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/index-old.vue")
  },
  {
    name: "index-old___th",
    path: "/index-old",
    meta: index_45oldaRZOhLTnmIMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/index-old.vue")
  },
  {
    name: "index-old___ru",
    path: "/index-old",
    meta: index_45oldaRZOhLTnmIMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/index-old.vue")
  },
  {
    name: "index-old___pt",
    path: "/index-old",
    meta: index_45oldaRZOhLTnmIMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/index-old.vue")
  },
  {
    name: "index-old___es",
    path: "/index-old",
    meta: index_45oldaRZOhLTnmIMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/index-old.vue")
  },
  {
    name: "index-old___fr",
    path: "/index-old",
    meta: index_45oldaRZOhLTnmIMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/index-old.vue")
  },
  {
    name: "index-old___de",
    path: "/index-old",
    meta: index_45oldaRZOhLTnmIMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/index-old.vue")
  },
  {
    name: "index-old___jp",
    path: "/index-old",
    meta: index_45oldaRZOhLTnmIMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/index-old.vue")
  },
  {
    name: "index-old___id",
    path: "/index-old",
    meta: index_45oldaRZOhLTnmIMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/index-old.vue")
  },
  {
    name: "index-old___ar",
    path: "/index-old",
    meta: index_45oldaRZOhLTnmIMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/index-old.vue")
  },
  {
    name: "index-old___en",
    path: "/index-old",
    meta: index_45oldaRZOhLTnmIMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/index-old.vue")
  },
  {
    name: "index___tw",
    path: "/",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/index.vue")
  },
  {
    name: "index___kr",
    path: "/",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/index.vue")
  },
  {
    name: "index___vn",
    path: "/",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/index.vue")
  },
  {
    name: "index___th",
    path: "/",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/index.vue")
  },
  {
    name: "index___ru",
    path: "/",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/index.vue")
  },
  {
    name: "index___pt",
    path: "/",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/index.vue")
  },
  {
    name: "index___jp",
    path: "/",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/index.vue")
  },
  {
    name: "index___id",
    path: "/",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/index.vue")
  },
  {
    name: "index___ar",
    path: "/",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/index.vue")
  },
  {
    name: "ldh-seo.html___tw",
    path: "/ldh/:seo().html",
    meta: _91seo_93_46htmlZr7ZNWHCosMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/ldh/[seo].html.vue")
  },
  {
    name: "ldh-seo.html___kr",
    path: "/ldh/:seo().html",
    meta: _91seo_93_46htmlZr7ZNWHCosMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/ldh/[seo].html.vue")
  },
  {
    name: "ldh-seo.html___vn",
    path: "/ldh/:seo().html",
    meta: _91seo_93_46htmlZr7ZNWHCosMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/ldh/[seo].html.vue")
  },
  {
    name: "ldh-seo.html___th",
    path: "/ldh/:seo().html",
    meta: _91seo_93_46htmlZr7ZNWHCosMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/ldh/[seo].html.vue")
  },
  {
    name: "ldh-seo.html___ru",
    path: "/ldh/:seo().html",
    meta: _91seo_93_46htmlZr7ZNWHCosMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/ldh/[seo].html.vue")
  },
  {
    name: "ldh-seo.html___pt",
    path: "/ldh/:seo().html",
    meta: _91seo_93_46htmlZr7ZNWHCosMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/ldh/[seo].html.vue")
  },
  {
    name: "ldh-seo.html___es",
    path: "/ldh/:seo().html",
    meta: _91seo_93_46htmlZr7ZNWHCosMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/ldh/[seo].html.vue")
  },
  {
    name: "ldh-seo.html___fr",
    path: "/ldh/:seo().html",
    meta: _91seo_93_46htmlZr7ZNWHCosMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/ldh/[seo].html.vue")
  },
  {
    name: "ldh-seo.html___de",
    path: "/ldh/:seo().html",
    meta: _91seo_93_46htmlZr7ZNWHCosMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/ldh/[seo].html.vue")
  },
  {
    name: "ldh-seo.html___jp",
    path: "/ldh/:seo().html",
    meta: _91seo_93_46htmlZr7ZNWHCosMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/ldh/[seo].html.vue")
  },
  {
    name: "ldh-seo.html___id",
    path: "/ldh/:seo().html",
    meta: _91seo_93_46htmlZr7ZNWHCosMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/ldh/[seo].html.vue")
  },
  {
    name: "ldh-seo.html___ar",
    path: "/ldh/:seo().html",
    meta: _91seo_93_46htmlZr7ZNWHCosMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/ldh/[seo].html.vue")
  },
  {
    name: "ldh-seo.html___en",
    path: "/ldh/:seo().html",
    meta: _91seo_93_46htmlZr7ZNWHCosMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/ldh/[seo].html.vue")
  },
  {
    name: "other-about.html___tw",
    path: "/other/about.html",
    meta: about_46htmlnLFL2ojP0EMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/about.html.vue")
  },
  {
    name: "other-about.html___kr",
    path: "/other/about.html",
    meta: about_46htmlnLFL2ojP0EMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/about.html.vue")
  },
  {
    name: "other-about.html___vn",
    path: "/other/about.html",
    meta: about_46htmlnLFL2ojP0EMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/about.html.vue")
  },
  {
    name: "other-about.html___th",
    path: "/other/about.html",
    meta: about_46htmlnLFL2ojP0EMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/about.html.vue")
  },
  {
    name: "other-about.html___ru",
    path: "/other/about.html",
    meta: about_46htmlnLFL2ojP0EMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/about.html.vue")
  },
  {
    name: "other-about.html___pt",
    path: "/other/about.html",
    meta: about_46htmlnLFL2ojP0EMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/about.html.vue")
  },
  {
    name: "other-about.html___es",
    path: "/other/about.html",
    meta: about_46htmlnLFL2ojP0EMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/about.html.vue")
  },
  {
    name: "other-about.html___fr",
    path: "/other/about.html",
    meta: about_46htmlnLFL2ojP0EMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/about.html.vue")
  },
  {
    name: "other-about.html___de",
    path: "/other/about.html",
    meta: about_46htmlnLFL2ojP0EMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/about.html.vue")
  },
  {
    name: "other-about.html___jp",
    path: "/other/about.html",
    meta: about_46htmlnLFL2ojP0EMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/about.html.vue")
  },
  {
    name: "other-about.html___id",
    path: "/other/about.html",
    meta: about_46htmlnLFL2ojP0EMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/about.html.vue")
  },
  {
    name: "other-about.html___ar",
    path: "/other/about.html",
    meta: about_46htmlnLFL2ojP0EMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/about.html.vue")
  },
  {
    name: "other-about.html___en",
    path: "/other/about.html",
    meta: about_46htmlnLFL2ojP0EMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/about.html.vue")
  },
  {
    name: "other-gdpr.html___tw",
    path: "/other/gdpr.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/gdpr.html.vue")
  },
  {
    name: "other-gdpr.html___kr",
    path: "/other/gdpr.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/gdpr.html.vue")
  },
  {
    name: "other-gdpr.html___vn",
    path: "/other/gdpr.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/gdpr.html.vue")
  },
  {
    name: "other-gdpr.html___th",
    path: "/other/gdpr.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/gdpr.html.vue")
  },
  {
    name: "other-gdpr.html___ru",
    path: "/other/gdpr.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/gdpr.html.vue")
  },
  {
    name: "other-gdpr.html___pt",
    path: "/other/gdpr.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/gdpr.html.vue")
  },
  {
    name: "other-gdpr.html___es",
    path: "/other/gdpr.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/gdpr.html.vue")
  },
  {
    name: "other-gdpr.html___fr",
    path: "/other/gdpr.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/gdpr.html.vue")
  },
  {
    name: "other-gdpr.html___de",
    path: "/other/gdpr.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/gdpr.html.vue")
  },
  {
    name: "other-gdpr.html___jp",
    path: "/other/gdpr.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/gdpr.html.vue")
  },
  {
    name: "other-gdpr.html___id",
    path: "/other/gdpr.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/gdpr.html.vue")
  },
  {
    name: "other-gdpr.html___ar",
    path: "/other/gdpr.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/gdpr.html.vue")
  },
  {
    name: "other-gdpr.html___en",
    path: "/other/gdpr.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/gdpr.html.vue")
  },
  {
    name: "other-privacy.html___tw",
    path: "/other/privacy.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/privacy.html.vue")
  },
  {
    name: "other-privacy.html___kr",
    path: "/other/privacy.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/privacy.html.vue")
  },
  {
    name: "other-privacy.html___vn",
    path: "/other/privacy.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/privacy.html.vue")
  },
  {
    name: "other-privacy.html___th",
    path: "/other/privacy.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/privacy.html.vue")
  },
  {
    name: "other-privacy.html___ru",
    path: "/other/privacy.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/privacy.html.vue")
  },
  {
    name: "other-privacy.html___pt",
    path: "/other/privacy.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/privacy.html.vue")
  },
  {
    name: "other-privacy.html___es",
    path: "/other/privacy.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/privacy.html.vue")
  },
  {
    name: "other-privacy.html___fr",
    path: "/other/privacy.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/privacy.html.vue")
  },
  {
    name: "other-privacy.html___de",
    path: "/other/privacy.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/privacy.html.vue")
  },
  {
    name: "other-privacy.html___jp",
    path: "/other/privacy.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/privacy.html.vue")
  },
  {
    name: "other-privacy.html___id",
    path: "/other/privacy.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/privacy.html.vue")
  },
  {
    name: "other-privacy.html___ar",
    path: "/other/privacy.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/privacy.html.vue")
  },
  {
    name: "other-privacy.html___en",
    path: "/other/privacy.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/privacy.html.vue")
  },
  {
    name: "other-protocol.html___tw",
    path: "/other/protocol.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/protocol.html.vue")
  },
  {
    name: "other-protocol.html___kr",
    path: "/other/protocol.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/protocol.html.vue")
  },
  {
    name: "other-protocol.html___vn",
    path: "/other/protocol.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/protocol.html.vue")
  },
  {
    name: "other-protocol.html___th",
    path: "/other/protocol.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/protocol.html.vue")
  },
  {
    name: "other-protocol.html___ru",
    path: "/other/protocol.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/protocol.html.vue")
  },
  {
    name: "other-protocol.html___pt",
    path: "/other/protocol.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/protocol.html.vue")
  },
  {
    name: "other-protocol.html___es",
    path: "/other/protocol.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/protocol.html.vue")
  },
  {
    name: "other-protocol.html___fr",
    path: "/other/protocol.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/protocol.html.vue")
  },
  {
    name: "other-protocol.html___de",
    path: "/other/protocol.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/protocol.html.vue")
  },
  {
    name: "other-protocol.html___jp",
    path: "/other/protocol.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/protocol.html.vue")
  },
  {
    name: "other-protocol.html___id",
    path: "/other/protocol.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/protocol.html.vue")
  },
  {
    name: "other-protocol.html___ar",
    path: "/other/protocol.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/protocol.html.vue")
  },
  {
    name: "other-protocol.html___en",
    path: "/other/protocol.html",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/other/protocol.html.vue")
  },
  {
    name: "search___tw",
    path: "/search",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/search.vue")
  },
  {
    name: "search___kr",
    path: "/search",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/search.vue")
  },
  {
    name: "search___vn",
    path: "/search",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/search.vue")
  },
  {
    name: "search___th",
    path: "/search",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/search.vue")
  },
  {
    name: "search___ru",
    path: "/search",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/search.vue")
  },
  {
    name: "search___pt",
    path: "/search",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/search.vue")
  },
  {
    name: "search___es",
    path: "/search",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/search.vue")
  },
  {
    name: "search___fr",
    path: "/search",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/search.vue")
  },
  {
    name: "search___de",
    path: "/search",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/search.vue")
  },
  {
    name: "search___jp",
    path: "/search",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/search.vue")
  },
  {
    name: "search___id",
    path: "/search",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/search.vue")
  },
  {
    name: "search___ar",
    path: "/search",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/search.vue")
  },
  {
    name: "search___en",
    path: "/search",
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/search.vue")
  },
  {
    name: "test___tw",
    path: "/test",
    meta: testPzJJRUQQeZMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/test.vue")
  },
  {
    name: "test___kr",
    path: "/test",
    meta: testPzJJRUQQeZMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/test.vue")
  },
  {
    name: "test___vn",
    path: "/test",
    meta: testPzJJRUQQeZMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/test.vue")
  },
  {
    name: "test___th",
    path: "/test",
    meta: testPzJJRUQQeZMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/test.vue")
  },
  {
    name: "test___ru",
    path: "/test",
    meta: testPzJJRUQQeZMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/test.vue")
  },
  {
    name: "test___pt",
    path: "/test",
    meta: testPzJJRUQQeZMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/test.vue")
  },
  {
    name: "test___es",
    path: "/test",
    meta: testPzJJRUQQeZMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/test.vue")
  },
  {
    name: "test___fr",
    path: "/test",
    meta: testPzJJRUQQeZMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/test.vue")
  },
  {
    name: "test___de",
    path: "/test",
    meta: testPzJJRUQQeZMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/test.vue")
  },
  {
    name: "test___jp",
    path: "/test",
    meta: testPzJJRUQQeZMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/test.vue")
  },
  {
    name: "test___id",
    path: "/test",
    meta: testPzJJRUQQeZMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/test.vue")
  },
  {
    name: "test___ar",
    path: "/test",
    meta: testPzJJRUQQeZMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/test.vue")
  },
  {
    name: "test___en",
    path: "/test",
    meta: testPzJJRUQQeZMeta || {},
    component: () => import("/root/workspace/ld_x_gw_Wdkc/pages/test.vue")
  }
]