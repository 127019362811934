export default defineNuxtRouteMiddleware(() => {
  if (import.meta.server && !isDev()) {
    const headers = useRequestHeaders()
    const url = useRequestURL()
    const ip = headers['x-forwarded-for']
    const userAgent = headers['user-agent']
    serverLog.log(`[${+new Date()}] view    ip: ${ip}, url: ${url.href}, userAgent:${userAgent}`)
  }

  const { resetLanguage } = useLanguageStore()
  const { resetConf } = useLayout()
  resetLanguage()
  resetConf()
})
