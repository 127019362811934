export default defineNuxtPlugin((nuxt) => {
  if (isProd())
    return
  // 为了方便在浏览器调试，把一些通用的东西放到window
  window.nuxt = nuxt
})

declare global {
  interface Window {
    nuxt: Record<string, any>

    // gtm 参数
    dataLayer?: any[]
  }
}
